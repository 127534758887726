import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const DTdeliAPI = {
  getDelis: params => getNoCatch(`${topic.DTdeliTopic}/getDelis`, params),
  getDTdeliById: params => getNoCatch(`${topic.DTdeliTopic}/getDelisById`, params),
  editDeli: params => postNoCatch(`${topic.DTdeliTopic}/editDeli`, params),
  addDeli: params => postNoCatch(`${topic.DTdeliTopic}/addDeli`, params),
  addRequisitionByDtdeli: params => postNoCatch(`${topic.requisitionTopic}/addRequisitionByDtdeli`, params),
  exportDTDeliExcel: params => getNoCatch(`${topic.DTdeliTopic}/exportDTDeliExcel`, params)
};
