<template>
  <div>
    <div v-for="item in list" :key="item.permId" class="text item">
      <el-link type="info" class="vg_cursor_hander" @click="jumpList(item.route, item.permId)">{{ item.moudlename }}</el-link>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { podrAPI } from '@/api/modules/podr';
import UrlEncode from '@assets/js/UrlEncode';
export default {
  name: 'RelationList',
  data() {
    return {
      list: [
        { permId: 115, moudlename: '供应商信息', route: '/supp_list' },
        { permId: 124, moudlename: '成品信息', route: '/prod_list' },
        { permId: 126, moudlename: '销售合同', route: '/scon_list' },
        { permId: 135, moudlename: '变更记录', route: '/podc_list' },
        { permId: 132, moudlename: '采购发票', route: '/pinv_list' },
        { permId: 131, moudlename: '进仓单', route: '/deli_list' }
      ],
      podrForm: {}
    };
  },
  created() {
    this.getSconInfo();
  },
  methods: {
    // 跳转
    jumpList(val, val1) {
      if (val1 === 115) {
        this.jump(val, { perm_id: val1, supp_id: this.podrForm.supp_id });
      } else if (val1 === 124) {
        let temp = [];
        this.podrForm.podr_part_list.forEach(item => {
          temp.push(item.prod_id);
        });
        let str = temp.join(',');
        this.jump(val, { perm_id: val1, prod_ids: str, prod_attr: 1 });
      } else if (val1 === 126) {
        this.jump(val, { perm_id: val1, scon_no: this.podrForm.scon_no });
      } else if (val1 === 135 || val1 === 132) {
        let str = this.podrForm.podr_no;
        this.jump(val, { perm_id: val1, podr_no: str });
      } else if (val1 === 131) {
        this.jump(val, { perm_id: val1, podr_no: this.podrForm.podr_no });
      }
    },
    //获取表单信息
    getSconInfo(val) {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      get(podrAPI.getPodrById, { podr_id: props.form_id })
        .then(res => {
          if (res.data.code === 0) {
            this.podrForm = res.data.data.form;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
</style>
